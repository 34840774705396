import React from "react";
import {ICorrespondance} from "src/Models/Correspondance.model";
import FileHelper from "src/Helpers/File.helper";

type CorrespondanceSignatureInformationsComponentProps = {
    correspondance: ICorrespondance
}

const CorrespondanceSignatureInformationsComponent = (props: CorrespondanceSignatureInformationsComponentProps ) => {

    return <div className="informations-wrapper">
        <div className="columns">

            <div className="column">
                <div className="informations-block">
                    <p className="label">Nom</p>
                    <p className="value">{props.correspondance.signatureNom || '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Prénom</p>
                    <p className="value">{props.correspondance.signaturePrenom || '-'}</p>
                </div>
                <div className="informations-block">
                    <p className="label">Fonction</p>
                    <p className="value">{props.correspondance.signatureFonction || '-'}</p>
                </div>
            </div>

            <div className="column">
                <div className="informations-block">
                    <p className="label">Fichier</p>
                    <div className="value">
                        {props.correspondance.signatureFichier &&
                            <figure className="informations-image -resume">
                                <img src={FileHelper.getImageUrlFromSize(props.correspondance.signatureFichier,"signature")} alt="" className="image" />
                            </figure>
                        }
                        {!props.correspondance.signatureFichier && <>-</>}
                    </div>
                </div>
            </div>
        </div>

    </div>

};

export default CorrespondanceSignatureInformationsComponent;
