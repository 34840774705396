import React, {useContext, useEffect, useState} from "react";
import LayoutComponent from "../Components/Layout/Layout.component";
import SearchBlockComponent, {IFormSearchData} from "../Components/Search/SearchBlock.component";
import SearchResultHeaderComponent from "../Components/Search/SearchResultHeader.component";
import SearchResultComponent from "../Components/Search/SearchResult.component";
import SeeMoreLinkComponent from "../Components/Links/SeeMoreLink.component";
import {IGlobalSearchApiResponse, IGlobalSearchFormData, SearchService} from "../Services/Search.service";
import ErrorFormsMessagesComponent from "../Components/Errors/ErrorFormsMessages.component";
import ErrorsConstants from "../Constants/Errors";
import LoadingComponent from "../Components/Loading/Loading.component";
import {INotaire} from "../Models/Notaire.model";
import NotairesHelper from "../Helpers/Notaires.helper";
import {IOffice} from "../Models/Office.model";
import OfficesHelper from "../Helpers/Offices.helper";
import {useHistory, useLocation} from "react-router";
import {IOrganisme} from "../Models/Organisme.model";
import {IPersonneExterieure} from "../Models/PersonneExterieure.model";
import OrganismesHelper from "../Helpers/Organismes.helper";
import PersonnesExterieuresHelper from "../Helpers/PersonnesExterieures.helper";
import FileHelper from "../Helpers/File.helper";
import {AppConstants} from "../Constants/AppConstants";
import {ISuccession} from "../Models/Succession.model";
import {IFormation} from "../Models/Formation.model";
import DateHelper from "../Helpers/Date.helper";
import {ISinistre} from "../Models/Sinistre.model";
import SinistresHelper from "../Helpers/Sinistres.helper";
import {IReclamation} from "../Models/Reclamation.model";
import {IDifferend} from "../Models/Differend.model";
import ReclamationsHelper from "../Helpers/Reclamations.helper";
import {IUserContext, UserContext} from "../Providers/User.provider";

const SearchScreen = () => {

    const location = useLocation();
    const history = useHistory();
    const searchService: SearchService = new SearchService();
    const [keyword, setKeyword] = useState("");
    const [error, setError] = useState("");
    const [results, setResults] = useState<IGlobalSearchApiResponse>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {isAuthenticated, checkModuleAllowed} = useContext(UserContext) as IUserContext;

    useEffect(()=>{
        const params: any = location.search;
        let currentUrlParams = new URLSearchParams(params);
        const urlKeyword = currentUrlParams.get('keyword');
        if(urlKeyword){
            setKeyword(urlKeyword);
            doSearch({keyword: urlKeyword});
        }
    },[]);


    /**
     * Prend en charge la soumission du formulaire de recherche
     * @param {IFormSearchData} formData
     */
    const onSearchSubmitted = (formData: IFormSearchData) => {
        const searchKeyword = formData.keyword as string;

        if(!searchKeyword){
            setError(ErrorsConstants.messages.emptySearchField);
            return;
        }

        setKeyword(searchKeyword);
        doSearch({keyword: searchKeyword});
        history.replace(`/recherche?keyword=${searchKeyword}`);
    };

    /**
     * Effectue la recherche globale
     * @param {string} data
     */
    const doSearch = (data: IGlobalSearchFormData): void => {
        setError("");
        setIsLoading(true);
        searchService.doGlobalSearch(data).then((searchResults) => {
            setResults(searchResults);
            setIsLoading(false);
        }).catch((e) => {
            setError(ErrorsConstants.messages.apiCall);
            console.error(e);
        });
    };

    const hasResults = (): boolean => {
        return results.datas.personnesExterieures.length > 0
            || results.datas.organismes.length > 0
            || results.datas.notaires.length > 0
            || results.datas.offices.length > 0
            || results.datas.formations.length > 0
            || results.datas.differends.length > 0
            || results.datas.reclamations.length > 0
            || results.datas.sinistres.length > 0
            || results.datas.successions.length > 0
            || results.datas.anciensNotaires.length > 0;
    };

    return <LayoutComponent showMenu={true}>
        <div className="search-header">
            {error && <ErrorFormsMessagesComponent messages={[error]}/>}
            <SearchBlockComponent keyword={keyword} onSubmit={onSearchSubmitted} modificators="-big -searchpage" searchAuto={true}/>
        </div>


        {isLoading && <LoadingComponent/>}


        {!results && !isLoading &&
		<p className="search-empty-search">Veuillez saisir un ou plusieurs mots clé dans le champ de recherche
			ci-dessus.</p>}

        {results !== null && !hasResults() && <p className="search-empty-search">Aucun résultat pour cette recherche.</p>}

        {results !== null && hasResults() && !isLoading && <div className="search-content">
			<h1 className="title">Résultats de recherche pour : {keyword}</h1>

            {/* NOTAIRES */}
            {checkModuleAllowed('annuaire') && results.datas.notaires && results.datas.notaires.length > 0 && <>
				<SearchResultHeaderComponent icon="icon-notaires" text="Notaires">
                    {results.datas.notaires.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathNotaires}/?page=1&keyword=${keyword}&tab=list`}/>}
				</SearchResultHeaderComponent>

				<div className="results">
                    {
                        results.datas.notaires.map((notaire: INotaire, nIndex) => {
                        if(nIndex > 2) return null; // Max 3 résultats affichés

                        let photo: string = notaire.photo;
                        if( photo ){
                            photo = FileHelper.getImageUrlFromSize(notaire.photo,"thumbnail")
                        }
                        else{
                            photo = FileHelper.getPlaceholderUrl("placeholder-man");
                        }

                        return <SearchResultComponent
                            key={`notaire_${nIndex}`}
                            link={`${AppConstants.pathNotaires}/${notaire.id}`}
                            title={`${notaire.prenom} ${notaire.nom}`}
                            subtitle={NotairesHelper.getFormattedType(notaire.type)}
                            illustration={photo}
                            texts={[
                                notaire.office ? `${notaire.office.CRPCEN} - ${notaire.office.nom}` : null,
                                notaire.office ? OfficesHelper.getMainAddress(notaire.office) : null,
                            ]}
                        />
                    })
                    }
				</div>
			</>}

            {/* Anciens NOTAIRES */}
            {checkModuleAllowed('annuaire') && results.datas.anciensNotaires && results.datas.anciensNotaires.length > 0 && <>
				<SearchResultHeaderComponent icon="icon-notaires" text="Anciens Notaires">
                    {results.datas.anciensNotaires.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathNotairesAnciens}/?page=1&keyword=${keyword}&tab=list`}/>}
				</SearchResultHeaderComponent>

				<div className="results">
                    {results.datas.anciensNotaires.map((notaire: INotaire, nIndex) => {
                        if(nIndex > 2) return null; // Max 3 résultats affichés

                        let photo: string = notaire.photo;
                        if( photo ){
                            photo = FileHelper.getImageUrlFromSize(notaire.photo,"thumbnail")
                        }
                        else{
                            photo = FileHelper.getPlaceholderUrl("placeholder-man");
                        }

                        return <SearchResultComponent
                            key={`ancienNotaire_${nIndex}`}
                            link={`${AppConstants.pathNotaires}/${notaire.id}`}
                            title={`${notaire.prenom} ${notaire.nom}`}
                            subtitle={NotairesHelper.getFormattedType(notaire.type)}
                            illustration={photo}
                            texts={[
                                notaire.office ? `${notaire.office.CRPCEN} - ${notaire.office.nom}` : null,
                                notaire.office ? OfficesHelper.getMainAddress(notaire.office) : null,
                            ]}
                        />
                    })}
				</div>
			</>}

            {/* OFFICES */}
            {checkModuleAllowed('annuaire') && results.datas.offices && results.datas.offices.length > 0 && <>
				<SearchResultHeaderComponent icon="icon-office" text="Offices">
					{results.datas.offices.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathOffices}/?page=1&keyword=${keyword}&tab=list`}/>}
				</SearchResultHeaderComponent>

				<div className="results">
                    {results.datas.offices.map((office: IOffice, oIndex) => {
                        if(oIndex > 2) return null; // Max 3 résultats affichés
                        return <SearchResultComponent
                            key={`office_${oIndex}`}
                            link={`${AppConstants.pathOffices}/${office.id}`}
                            title={`${office.nom}`}
                            texts={[
                                `${office.CRPCEN}`,
                                OfficesHelper.getFormatedType(office.type),
                                OfficesHelper.getMainAddress(office),
                            ]}
                        />
                    })}
				</div>
			</>}

            {/* Organismes */}
            {checkModuleAllowed('annuaire') && results.datas.organismes && results.datas.organismes.length > 0 && <>
				<SearchResultHeaderComponent icon="icon-organismes" text="Organismes">
					{results.datas.organismes.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathOrganismes}/?page=1&keyword=${keyword}&tab=list`}/>}
				</SearchResultHeaderComponent>

				<div className="results">
                    {results.datas.organismes.map((item: IOrganisme, oIndex) => {
                        if(oIndex > 2) return null; // Max 3 résultats affichés

                        const link: string = `${AppConstants.pathOrganismes}/${item.id}`;

                        return <SearchResultComponent
                            key={`organisme_${oIndex}`}
                            link={link}
                            title={`${item.nom}`}
                            texts={[OrganismesHelper.getMainAddress(item)]}
                        />
                    })}
				</div>
			</>}

            {/* Personnes Extérieures */}
            {checkModuleAllowed('annuaire') && results.datas.personnesExterieures && results.datas.personnesExterieures.length > 0 && <>
				<SearchResultHeaderComponent icon="icon-personne-exterieure" text="Personnes Extérieures">
					{results.datas.personnesExterieures.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathPersonnesExterieures}/?page=1&keyword=${keyword}&tab=list`}/>}
				</SearchResultHeaderComponent>

				<div className="results">
                    {results.datas.personnesExterieures.map((item: IPersonneExterieure, oIndex) => {
                        if(oIndex > 2) return null; // Max 3 résultats affichés

                        const link: string = `${AppConstants.pathPersonnesExterieures}/${item.id}`;

                        return <SearchResultComponent
                            key={`personne_${oIndex}`}
                            link={link}
                            title={`${item.nom} ${item.prenom}`}
                            texts={[
                                PersonnesExterieuresHelper.getMainMail(item as IPersonneExterieure),
                                item.fonction
                            ]}
                        />
                    })}
				</div>
			</>}




            {/* Successions */}
            {
                checkModuleAllowed('successions') && results.datas.successions && results.datas.successions.length > 0 &&
                <>
                    <SearchResultHeaderComponent icon="icon-successions" text="Successions">
                        {results.datas.successions.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathSuccessions}/?page=1&keyword=${keyword}&tab=list`}/>}
                    </SearchResultHeaderComponent>

                    <div className="results">
                        {results.datas.successions.map((succession: ISuccession, oIndex) => {
                            if(oIndex > 2) return null; // Max 3 résultats affichés
                            return <SearchResultComponent
                                key={`succession_${oIndex}`}
                                link={`${AppConstants.pathSuccessions}/${succession.id}`}
                                title={`${succession.identiteNom} ${succession.identitePrenom}`}
                                texts={[
                                    `${succession.demandeurNom} ${succession.demandeurPrenom}`,
                                ]}
                            />
                        })}
                    </div>
                </>
            }



            {/* Formations */}
            {
                checkModuleAllowed('formations') && results.datas.formations && results.datas.formations.length > 0 &&
                <>
                    <SearchResultHeaderComponent icon="icon-formations" text="Formations">
                        {results.datas.formations.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathFormations}/?page=1&keyword=${keyword}&tab=a-venir`}/>}
                    </SearchResultHeaderComponent>

                    <div className="results">
                        {results.datas.formations.map((formation: IFormation, oIndex) => {
                            if(oIndex > 2) return null; // Max 3 résultats affichés
                            return <SearchResultComponent
                                key={`formation_${oIndex}`}
                                link={`${AppConstants.pathFormations}/${formation.id}`}
                                title={`${formation.titre}`}
                                texts={[
                                    `${DateHelper.getFormatedDate(formation.date)}`,
                                ]}
                            />
                        })}
                    </div>
                </>
            }

            {/* Sinistres */}
            {
                checkModuleAllowed('sinistres') &&  results.datas.sinistres && results.datas.sinistres.length > 0 &&
                <>
                    <SearchResultHeaderComponent icon="icon-sinistres" text="Sinistres">
                        {results.datas.sinistres.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathSinistres}/?page=1&keyword=${keyword}&tab=list`}/>}
                    </SearchResultHeaderComponent>

                    <div className="results">
                        {results.datas.sinistres.map((sinistre: ISinistre, oIndex) => {
                            if(oIndex > 2) return null; // Max 3 résultats affichés
                            return <SearchResultComponent
                                key={`sinistres_${oIndex}`}
                                link={`${AppConstants.pathSinistres}/${sinistre.id}`}
                                title={ SinistresHelper.getFormatedNotaires(sinistre.notairesDemandeurs)}
                                texts={[ sinistre.libelle]}
                            />
                        })}
                    </div>
                </>
            }

            {/* Réclamations */}
            {
                checkModuleAllowed('reclamations') && results.datas.reclamations && results.datas.reclamations.length > 0 &&
                <>
                    <SearchResultHeaderComponent icon="icon-reclamations" text="Réclamations">
                        {results.datas.reclamations.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathReclamations}/?page=1&keyword=${keyword}&tab=en-cours`}/>}
                    </SearchResultHeaderComponent>

                    <div className="results">
                        {results.datas.reclamations.map((reclamation: IReclamation, oIndex) => {
                            if(oIndex > 2) return null; // Max 3 résultats affichés
                            return <SearchResultComponent
                                key={`reclamations_${oIndex}`}
                                link={`${AppConstants.pathReclamations}/${reclamation.id}`}
                                title={ ReclamationsHelper.getNomPlaignants(reclamation) }
                                texts={[ ReclamationsHelper.getNomMisEnCause(reclamation) ]}
                            />
                        })}
                    </div>
                </>
            }

            {/* Differends */}
            {
                checkModuleAllowed('differends') && results.datas.differends && results.datas.differends.length > 0 &&
                <>
                    <SearchResultHeaderComponent icon="icon-differends" text="Différends">
                        {results.datas.differends.length > 3 && <SeeMoreLinkComponent text={"Voir tous les résultats"} icon="icon-voir" link={`${AppConstants.pathDifferends}/?page=1&keyword=${keyword}&tab=en_cours`}/>}
                    </SearchResultHeaderComponent>

                    <div className="results">
                        {results.datas.differends.map((differend: IDifferend, oIndex) => {
                            if(oIndex > 2) return null; // Max 3 résultats affichés
                            return <SearchResultComponent
                                key={`differends_${oIndex}`}
                                link={`${AppConstants.pathDifferends}/${differend.id}`}
                                title={`${differend.notairePlaignant ? differend.notairePlaignant.nom + ' ' + differend.notairePlaignant.prenom : differend.notairePlaignantNom } / ${differend.notaireMisEnCause ? differend.notaireMisEnCause.nom + ' ' + differend.notaireMisEnCause.prenom : differend.notaireMisEnCauseNom} `}
                            />
                        })}
                    </div>
                </>
            }

		</div>}
    </LayoutComponent>
};

export default SearchScreen;
