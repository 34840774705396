import {
    IAnnexeCreateFormData,
    IOfficeGetApiResponse,
    IOfficesGetAllApiResponse,
    OfficesService
} from "src/Services/Offices.service";
import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {IOffice} from "src/Models/Office.model";
import OfficesHelper from "src/Helpers/Offices.helper";


type IAnnexeCreationFormComponentProps = {
    office?: IOffice,
    isOnCreationStep?: boolean,
    
    submitMethod: (formData: IAnnexeCreateFormData) => Promise<IOfficeGetApiResponse>,
    onSuccessSubmit: (response: IOfficeGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IOfficeGetApiResponse) => void,
    onCancel: () => void,
}

export default function AnnexeCreationFormComponent(props: IAnnexeCreationFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [offices, setOffices] = useState<IOffice[]>(null);
    const [officesLoaded, setOfficesLoaded] = useState<boolean>(false);

    /**
     * Initialisation
     */
    useEffect(() => {
        const officesService: OfficesService = new OfficesService();
        officesService.getAllOfficesPrincipales().then((response: IOfficesGetAllApiResponse) => {
            if( response && response.datas && response.datas.offices){
                setOffices(response.datas.offices);
                setOfficesLoaded(true);
            }
        });
    }, []);


    /**
     * Initialisation du formulaire
     */
    useEffect(() => {
        const office: IOffice = props.office;

        const currentFormColumns: FormColumn[] = [
            {
                elements: [
                    {
                        type: 'text',
                        fieldName: "nom",

                        label: "Nom de l'office",
                        placeholder: "Nom de l'office",
                        required: true,
                        modificators: props.isOnCreationStep ? "-on-white" : "",

                        oldValue: office && office.nom ? office.nom : "",
                    }
                ],
            },
            {
                elements: [
                    {
                        type: 'select',
                        fieldName: "officeId",

                        label: "Office principal",
                        placeholder: "Office principal",
                        required: true,
                        options: OfficesHelper.formatListForOptions(offices),
                        modificators: props.isOnCreationStep ? "-on-white" : "",

                        oldValue: office && office.officePrincipale && office.officePrincipale.id ? office.officePrincipale.id.toString() : "",
                    }
                ],

            },
        ];
        setFormColumns(currentFormColumns);



        const currentFormActions: FormActions[] = [
            {
                label: "Annuler",
                modificators: "-is-link -white",
                onAction: onCancel
            },
            {
                label: "Enregistrer",
                modificators: "-secondary-border-only",
                onAction: (formData) => {
                    onSubmit(formData, false)
                }
            }
        ];
        setFormActions(currentFormActions);
    }, [offices]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IAnnexeCreateFormData).then((response: IOfficeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'annexe a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création de l'annexe.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                officesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
            }

        </div>
    )

}
