import {SinistresConstants} from "../Constants/SinistresConstants";
import CommonHelper from "./Common.helper";
import {INotaire} from "../Models/Notaire.model";


export default class SinistresHelper {

    /**
     * Retourne la nature formatée
     *
     * @param {string} nature
     * @returns {string}
     */
    static getFormatedNature(nature: string): string {
        return CommonHelper.getFormatedElementFromOptions(nature, SinistresConstants.natureOptions);
    }

    /**
     * Retourne l'importance formaté
     *
     * @param {string} importance
     * @returns {string}
     */
    static getFormatedImportance(importance: string): string {
        return CommonHelper.getFormatedElementFromOptions(importance, SinistresConstants.importanceOptions);
    }

    /**
     * Retourne la civilité formatée
     *
     * @param {string} civilite
     * @returns {string}
     */
    static getFormatedCivilite(civilite: string): string {
        return CommonHelper.getFormatedElementFromOptions(civilite, SinistresConstants.civiliteOptions);
    }


    /**
     * Retourne le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedDocumentTypes(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, SinistresConstants.documentsTypesOptions);
    }


    /**
     * Récupère une liste de notaire formatée pour le sinistre
     *
     * @param {INotaire[]} notaires
     * @returns {string}
     */
    static getFormatedNotaires(notaires: INotaire[]) : string{
        const notairesFormated = [];

        for(let notaire of notaires){
            notairesFormated.push(`${notaire.nom} ${notaire.prenom}`);
        }

        return notairesFormated.join(', ');
    }



}

