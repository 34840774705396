import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {ILabelGetApiResponse, ILabelInformationsFormData} from "src/Services/Labels.service";
import {ILabel} from "src/Models/Label.model";
import {LabelsConstants} from "src/Constants/LabelsConstants";


type ILabelsInformationsFormProps = {
    label?: ILabel,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ILabelInformationsFormData) => Promise<ILabelGetApiResponse>,
    onSuccessSubmit: (response: ILabelGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ILabelGetApiResponse) => void,
    onCancel: () => void,
}

export default function LabelsInformationsFormComponent(props: ILabelsInformationsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();



    useEffect(()=>{
        const label: ILabel = props.label;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: !label ? "-on-white" : '',
                oldValue: label && label.nom ? label.nom : "",
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'select',
                fieldName: "module",

                label: "Module",
                placeholder: "Module",
                required: true,
                hideSearchOnMultiple: true,
                options: LabelsConstants.moduleOptions,
                modificators: !label ? "-on-white" : '',
                oldValue: label && label.module ? label.module : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'file',
                fieldName: "fichier",

                label: "Logo",
                placeholder: "Logo",
                required: false,
                showImage: true,

                oldValue: label && label.fichier ? label.fichier : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ILabelInformationsFormData).then((response: ILabelGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }


            const message: string = props.label ? "Le label a bien été modifié." : "Le label a bien été créé."
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: message,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création du label.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
