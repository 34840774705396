import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {ICorrespondanceGetApiResponse, ICorrespondanceFormData} from "src/Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import useCreationSteps from "src/Hooks/useCreationSteps";


type CorrespondanceCreateFormProps = {
    correspondance?: ICorrespondance,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICorrespondanceFormData) => Promise<ICorrespondanceGetApiResponse>,
    onSuccessSubmit: (response: ICorrespondanceGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICorrespondanceGetApiResponse) => void,
    onCancel: () => void,
}

export default function CorrespondanceCreateForm(props: CorrespondanceCreateFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilisateur
     */
    useEffect(()=>{
        const {correspondance} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "titre",

                label: "Titre",
                placeholder: "Titre",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: correspondance && correspondance.titre ? correspondance.titre : "",
            },
            {
                type: 'datetime-local',
                withTime: true,
                fieldName: "date",
                label: "Date",
                placeholder: "Date",
                required: false,
                oldValue: correspondance && correspondance.date ? correspondance.date : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            }
        ];
        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            }
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: ICorrespondanceGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La correspondance a bien été créée.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}
            />
        </div>
    )
}
