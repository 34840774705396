import ExportComponent from "src/Components/Exports/Export.component";
import React from "react";
import {AppConstants} from "src/Constants/AppConstants";

type NotairesExportComponentProps = {
    setShowExport: () => void,
    showExport: boolean,
    filters?: any,
    isAnciens?: boolean,
}
export default function NotairesExportComponent(props: NotairesExportComponentProps) {
    if (!props.showExport) return null;

    let notaireOfficeOptions = [
        {value: 'offices.CRPCEN', label: 'CRPCEN'},
        {value: 'offices.nom', label: 'Office'},
        {value: 'autres.dateArrivee', label: 'Date d\'arrivée'},

        {value: 'offices.actif', label: 'Office actif'},
        {value: 'officesAdresses.ligne1', label: 'Rue'},
        {value: 'officesAdresses.ligne2', label: 'Complément d\'adresse'},
        {value: 'officesAdresses.boitePostale', label: 'Boîte Postale'},
        {value: 'officesAdresses.codePostal', label: 'Code Postal'},
        {value: 'officesAdresses.ville', label: 'Ville'},
        {value: 'officesAdresses.arrondissement', label: 'Arrondissement'},
        {value: 'officesAdresses.tribunalJudiciaire', label: 'Tribunal Judiciaire'},
        {value: 'officesCoordonnees.emailOffice', label: 'Email Principal'},
        {value: 'officesCoordonnees.emailComptabiliteOffice', label: 'Email Comptabilité'},
        {value: 'officesCoordonnees.emailAutreOffice', label: 'Email Autre'},
        {value: 'officesCoordonnees.telephoneOffice', label: 'Téléphone Principal'},
        {value: 'officesCoordonnees.telephoneComptaOffice', label: 'Téléphone Comptabilité'},
        {value: 'officesCoordonnees.telephoneAutreOffice', label: 'Téléphone Autre'},
        {value: 'officesCoordonnees.siteInternetOffice', label: 'URL'},
    ];
    if(props.isAnciens){
        let notaireOfficeOptions = [
            {value: 'offices.CRPCEN', label: 'CRPCEN'},
            {value: 'offices.nom', label: 'Office'},
            {value: 'autres.dateArrivee', label: 'Date d\'arrivée'},
        ];
    }




    return (
        <ExportComponent title={ props.isAnciens ? "Anciens notaires" : "Notaires"}
                         icon="icon-notaires"
                         subTitle={ props.isAnciens ? "Export des anciens notaires" : "Export des notaires"}
                         onClose={() => props.setShowExport()}
                         modificators={`${!props.showExport ? '-hide' : ''}`}
                         filters={props.filters || null}
                         exportUrl= { props.isAnciens ? `api${AppConstants.pathNotaires}/export/anciens` : `api${AppConstants.pathNotaires}/export` }
                         columns={[
                             {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-notaires',
                                      fieldName: "identite",
                                      label: "Identité",
                                      options: [
                                          {value: 'nom', label: 'Nom'},
                                          {value: 'prenom', label: 'Prénom'},
                                          {value: 'fonctions', label: 'Fonction(s)'},
                                          {value: 'sexe', label: 'Sexe'},
                                          {value: 'type', label: 'Type de notaire'},
                                          {value: 'dateNaissance', label: 'Date de naissance'},
                                          {value: 'lieuNaissance', label: 'Lieu de naissance'},
                                          {value: 'CSN', label: 'N° CSN'},
                                          {value: 'labels', label: 'Labels'},
                                          {value: 'languesParlees', label: 'Langue(s) parlée(s)'},
                                          {value: 'agrementInspection', label: 'Agrément inspection'},
                                          {value: 'agrementComptabilite', label: 'Agrément comptabilité'},
                                          {value: 'siret', label: 'N° Siret'},
                                          {value: 'coordonnees.groupesEnvoi', label: 'Groupes'},
                                      ]
                                  },
                              ]
                          },
                          {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-repertoire',
                                      fieldName: "dates",
                                      label: "Dates importantes",
                                      options: [
                                          {value: 'dateSermentInitial', label: 'Date serment initial'},
                                          {value: 'dateDernierSerment', label: 'Date du dernier serment'},
                                          {value: 'dateNominationJournalOfficiel', label: 'Date JO (1e nomination)'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-coordonnees',
                                      fieldName: "coordonnees",
                                      label: "Coordonnées",
                                      options: [
                                          {value: 'coordonnees.emailPrincipal', label: 'Email principal'},
                                          {value: 'coordonnees.emailConfidentiel', label: 'Email Confidentiel (Perso)'},
                                          {value: 'coordonnees.emailConvocation', label: 'Email Convocation'},
                                          {value: 'coordonnees.telPortable', label: 'Téléphone portable'},
                                          {value: 'coordonnees.telLigneDirecte', label: 'Téléphone ligne directe'},
                                          {value: 'coordonnees.telAutre', label: 'Téléphone autre'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-coordonnees',
                                      fieldName: "adresses",
                                      label: "Adresse Perso",
                                      options: [
                                          {value: 'adressesPerso.ligne1Perso', label: 'Rue'},
                                          {value: 'adressesPerso.ligne2Perso', label: 'Complément d\'adresse'},
                                          {value: 'adressesPerso.boitePostalePerso', label: 'Boîte Postale'},
                                          {value: 'adressesPerso.codePostalPerso', label: 'Code Postal'},
                                          {value: 'adressesPerso.villePerso', label: 'Ville'},
                                      ]
                                  },
                              ]
                          },
                          {
                              elements: [
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-building',
                                      fieldName: "office",
                                      label: "Office",
                                      options: [
                                          {value: 'offices.CRPCEN', label: 'CRPCEN'},
                                          {value: 'offices.nom', label: 'Office'},
                                          {value: 'autres.dateArrivee', label: 'Date d\'arrivée'},

                                          {value: 'offices.actif', label: 'Office actif'},
                                          {value: 'officesAdresses.ligne1', label: 'Rue - Office'},
                                          {value: 'officesAdresses.ligne2', label: 'Complément d\'adresse - Office'},
                                          {value: 'officesAdresses.boitePostale', label: 'Boîte Postale - Office'},
                                          {value: 'officesAdresses.codePostal', label: 'Code Postal - Office'},
                                          {value: 'officesAdresses.ville', label: 'Ville - Office'},
                                          {value: 'officesAdresses.arrondissement', label: 'Arrondissement - Office'},
                                          {value: 'officesAdresses.tribunalJudiciaire', label: 'Tribunal Judiciaire- Office'},
                                          {value: 'officesCoordonnees.emailOffice', label: 'Email Principal - Office'},
                                          {value: 'officesCoordonnees.emailComptabiliteOffice', label: 'Email Comptabilité - Office'},
                                          {value: 'officesCoordonnees.emailAutreOffice', label: 'Email Autre - Office'},
                                          {value: 'officesCoordonnees.telephoneOffice', label: 'Téléphone Principal - Office'},
                                          {value: 'officesCoordonnees.telephoneComptaOffice', label: 'Téléphone Comptabilité - Office'},
                                          {value: 'officesCoordonnees.telephoneAutreOffice', label: 'Téléphone Autre - Office'},
                                          {value: 'officesCoordonnees.siteInternetOffice', label: 'URL - Office'},

                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-historique',
                                      fieldName: "historique",
                                      label: "Historique",
                                      options: [
                                          {value: 'autres.historiques', label: 'Historique'},
                                      ]
                                  },
                                  {
                                      type: 'checkboxGroup',
                                      icon: 'icon-commentaires',
                                      fieldName: "commentaires",
                                      label: "Commentaires",
                                      options: [
                                          {value: 'commentaires', label: 'Commentaires'},
                                      ]
                                  },
                              ]
                          },
                      ]}
        />
    )
}
