import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {ISuccession} from "src/Models/Succession.model";
import {ISuccessionDemandeurFormData, ISuccessionGetApiResponse} from "src/Services/Successions.service";


type ISuccessionDemandeurFormProps = {
    succession?: ISuccession,
    isOnCreationStep?: boolean

    submitMethod: (formData: ISuccessionDemandeurFormData) => Promise<ISuccessionGetApiResponse>,
    onSuccessSubmit: (response: ISuccessionGetApiResponse) => void,
    onCancel: () => void,
}

export default function SuccessionDemandeurFormComponent(props: ISuccessionDemandeurFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);



    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        const succession: ISuccession = props.succession;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "demandeurNom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurNom ? succession.demandeurNom : "",
            },
            {
                type: 'text',
                fieldName: "demandeurAdresse",

                label: "Adresse",
                placeholder: "Adresse",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurAdresse ? succession.demandeurAdresse : "",
            },
            {
                type: 'codePostalVille',
                fieldName: "cpVille",
                label: "Code Postal / Ville",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: succession && succession.demandeurCodePostal ? (succession.demandeurCodePostal + ' - ' + succession.demandeurVille ) : "",
                maxLength: 5,
            },
        ];

        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "demandeurPrenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurPrenom ? succession.demandeurPrenom : "",
            },
            {
                type: 'email',
                fieldName: "demandeurEmail",

                label: "Email",
                placeholder: "Email",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurEmail ? succession.demandeurEmail : "",
            },
            {
                type: 'text',
                fieldName: "demandeurMotif",

                label: "Motif",
                placeholder: "Motif",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurMotif ? succession.demandeurMotif : "",
            },
        ];

        const formElementsColumn3: FormElement[] = [
            {
                type: 'text',
                fieldName: "demandeurRaisonSociale",

                label: "Raison Sociale",
                placeholder: "Raison Sociale",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurRaisonSociale ? succession.demandeurRaisonSociale : "",
            },
            {
                type: 'tel',
                fieldName: "demandeurTelephone",

                label: "Téléphone",
                placeholder: "Téléphone",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: succession && succession.demandeurTelephone ? succession.demandeurTelephone : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [];
        currentFormActions = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                onAction: (formData) => {onSubmit(formData, false)}
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: onCancel
            }
        ];
        setFormActions(currentFormActions);

    },[props.succession]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISuccessionDemandeurFormData).then((response: ISuccessionGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations de la succession ont bien été modifiées.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de l'état de la succession.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
