import {ISinistre} from "../../Models/Sinistre.model";
import DateHelper from "../../Helpers/Date.helper";
import SinistresHelper from "../../Helpers/Sinistres.helper";

type ISinistreInformationsComponentProps = {
    sinistre: ISinistre,
}

export default function SinistreInformationsComponent(props: ISinistreInformationsComponentProps) {
    const sinistre: ISinistre = props.sinistre;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Numéro de dossier</p>
                        <p className="value">{sinistre.numeroDossier || '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Notaire(s) demandeur(s)</p>
                        <p className="value">{sinistre.notairesDemandeurs ? SinistresHelper.getFormatedNotaires(sinistre.notairesDemandeurs) : '-'}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Libellé du dossier</p>
                        <p className="value">{sinistre.libelle || '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Notaire(s) mis en cause</p>
                        <p className="value">{sinistre.notairesAssignes ? SinistresHelper.getFormatedNotaires(sinistre.notairesAssignes) : '-'}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Année d'ouverture</p>
                        <p className="value">{sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Importance du dossier</p>
                        <p className="value">{sinistre.importance ? SinistresHelper.getFormatedImportance(sinistre.importance) : '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
