import {IEvenement} from "src/Models/Evenement.model";
import DateHelper from "src/Helpers/Date.helper";
import NotairesHelper from "../../../../Helpers/Notaires.helper";

type IEvenementsInfosComponentProps = {
    evenements: IEvenement[],
    relationName: string,
}

export default function EvenementsInfosComponent(props: IEvenementsInfosComponentProps) {
    const evenements: IEvenement[] = props.evenements;

    return (
        <div className="informations-wrapper">
            {
                evenements && evenements.map((evenement: IEvenement, index: number) =>
                    <div className="columns" key={index} >
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date de début</p>
                                <p className="value">{evenement.dateDebut ? DateHelper.getFormatedDate(evenement.dateDebut): "-"}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date de fin</p>
                                <p className="value">{evenement.dateFin ? DateHelper.getFormatedDate(evenement.dateFin): "-"}</p>
                            </div>
                        </div>
                        <div className="column">
                            <div className="informations-block">
                                {/*Pour l'historique des fonctions notaires, le champ commentaire est utilisé pour la "Fonction" */}
                                <p className="label">{props.relationName === 'notaire' ? 'Fonction' : 'Commentaire'}</p>
                                <p className="value">
                                    {(!evenement.commentaire) && "-"}

                                    {(props.relationName !== 'notaire' && evenement.commentaire) && evenement.commentaire}

                                    {(props.relationName === 'notaire' && evenement.commentaire) &&
                                        NotairesHelper.getFormatedFonctions([evenement.commentaire])
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>
    );
}
