import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type ISinistresConstants = {
    natureOptions: IOption[],
    importanceOptions: IOption[],
    civiliteOptions: IOption[],
    creationSteps: ICreationSteps[],
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
}
export const SinistresConstants: ISinistresConstants = {
    natureOptions: [
        {
            value: "assignation",
            label: "Assignation",
        },
        {
            value: "avis",
            label: "Pour Avis",
        },
    ],

    importanceOptions: [
        {
            value: "basse",
            label: "Basse",
        },
        {
            value: "normale",
            label: "Normale",
        },
        {
            value: "haute",
            label: "Haute",
        },
    ],

    civiliteOptions: [
        {
            value: "madame-monsieur",
            label: "Madame et Monsieur",
        },
        {
            value: "monsieur",
            label: "Monsieur",
        },
        {
            value: "madame",
            label: "Madame",
        },
        {
            value: "maitre",
            label: "Cher Maître",
        },
    ],


    creationSteps: [
        {
            url: "/description",
            title: "Description",
            icon: "icon-description",
        },
        {
            url: "/documents",
            title: "Documents",
            icon: "icon-documents",
        },
    ],

    documentsTypesOptions: [
        {
            value: "presentation",
            label: "Fiche synthétique de présentation",
        },
        {
            value: "rapports",
            label: "Rapports du CTRC",
        },
        {
            value: "autre",
            label: "Autre",
        },
    ],

    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "type",
            label: "Type"
        },
    ]
};
