import React, {useEffect, useState} from "react";
import {CommonsService} from "src/Services/Commons.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {useHistory} from "react-router";
import {AppConstants} from "src/Constants/AppConstants";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import UseSinistre from "src/Hooks/UseSinistre";
import {ISinistre} from "src/Models/Sinistre.model";
import {
    ISinistreDescriptionFormData,
    ISinistreDocumentsFormData,
    ISinistreGetApiResponse,
    ISinistreInformationsFormData,
    SinistresService
} from "src/Services/Sinistres.service";
import SinistreInformationsFormComponent from "src/Components/Forms/Sinistres/SinistreInformationsForm.component";
import SinistreDocumentsFormForm from "src/Components/Forms/Sinistres/SinistreDocumentsForm.component";
import SinistreDescriptionFormComponent from "src/Components/Forms/Sinistres/SinistreDescriptionForm.component";

type ISinistreCreationStepsScreenProps = {
    sid?: number,
    type: string,
}


const SinistreCreationStepsScreen = (props:ISinistreCreationStepsScreenProps) => {
    const sinistresService: SinistresService = new SinistresService();
    const commonsService: CommonsService = new CommonsService();
    const {getSinistreById} = UseSinistre();
    const {redirectToNextCreationStep} = useCreationSteps();
    const [sinistre, setSinistre] = useState<ISinistre>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        if(props.sid){
            getSinistreById(props.sid.toString(), (s: ISinistre) => {
                setSinistre(s);
                setLoaded(true);
            })
        }
        else{
            setLoaded(true);
        }
    }, [props.sid]);


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {ISinistreGetApiResponse} response
     */
    const onSuccessSubmitForm = (response: ISinistreGetApiResponse): void => {
        if(response.datas && response.datas.sinistre){
            const sinistre: ISinistre = response.datas.sinistre;
            history.push(AppConstants.pathSinistres + '/' + sinistre.id);
        }
        else{
            history.push(AppConstants.pathSinistres);
        }
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {ISinistreGetApiResponse} response
     */
    const onSuccessSubmitNext = (response: ISinistreGetApiResponse): void => {
        if(response.datas && response.datas.sinistre){
            const sinistre: ISinistre = response.datas.sinistre;
            redirectToNextCreationStep(AppConstants.pathSinistres, sinistre.id, sinistre.creationSteps);
        }
        else{
            history.push(AppConstants.pathSinistres);
        }
    };


    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        history.push(AppConstants.pathSinistres);
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"} contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent text="Ajouter un sinistre" icon="icon-sinistres-add" modificators={`-in-fullscreen -border-full-screen`}/>

            {
                loaded &&
                <>
                    {
                        props.type === "informations" &&
                        <>
                            <InformationHeaderComponent icon={"icon-informations"} title={"Informations"} modificators="-margin-bottom" />
                            <SinistreInformationsFormComponent sinistre={sinistre} submitMethod={(formData: ISinistreInformationsFormData)=>sinistresService.create(formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }

                    {
                        props.type === "documents" &&
                        <>
                            <InformationHeaderComponent icon={"icon-documents"} title={"Documents"} modificators="-margin-bottom" />
                            <SinistreDocumentsFormForm sinistre={sinistre} submitMethod={(formData: ISinistreDocumentsFormData)=>sinistresService.updateDocuments(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }
                    {
                        props.type === "description" &&
                        <>
                            <InformationHeaderComponent icon={"icon-description"} title={"Description"} modificators="-margin-bottom" />
                            <SinistreDescriptionFormComponent sinistre={sinistre} submitMethod={(formData: ISinistreDescriptionFormData)=>sinistresService.updateDescription(props.sid,formData)} onSuccessSubmit={onSuccessSubmitForm} onSuccessSubmitNext={onSuccessSubmitNext} onCancel={onCancelForm} isOnCreationStep={true} />
                        </>
                    }

                </>
            }
        </LayoutComponent>
    )
}

export default SinistreCreationStepsScreen;
