import 'moment/locale/fr';
import {FormationConstants} from "../Constants/FormationsConstants";
import {IFormation} from "../Models/Formation.model";
import {IOption} from "../Components/Fields/Select.field.component";
import CommonHelper from "./Common.helper";
import {SinistresConstants} from "../Constants/SinistresConstants";

export default class FormationsHelper{

    /**
     * Permet de formater une liste de formations pour les selects
     * @param {IFormation[]} formations
     * @returns {IOption[]}
     */
    static formatListForOptions(formations: IFormation[]): IOption[]{
        if(!formations || !formations.length) return [];

        const options: IOption[] = [];
        for(let formation of formations){
            const option: IOption = {
                value: formation.id.toString(),
                label: formation.titre
            };

            options.push(option)
        }

        return options;
    }


    /**
     * Permet de formater une liste de documents d'une formation pour les selects
     * @param {IFormation[]} formation
     * @returns {IOption[]}
     */
    static formatDocumentsListForOptions(formation: IFormation): IOption[]{
        if(!formation || !formation) return [];

        const options: IOption[] = [];
        for(let doc of formation.documents){
            let fichierFormated = doc.fichier;
            fichierFormated = fichierFormated.replace('formations/'+formation.id+'/', '');

            const option: IOption = {
                value: doc.id.toString(),
                label: fichierFormated
            };

            options.push(option)
        }

        return options;
    }


    /**
     * Permet de récuépérer une liste d'id à partir d'une liste de formations
     *
     * @param {IFormation[]} formations
     * @returns {string[]}
     */
    public static getIdsFromList(formations: IFormation[]): string[]{
        if(!formations || !formations.length) return [];

        const ids: string[] = formations.map((formation: IFormation) => formation.id.toString());

        return ids;
    }

    /**
     * Permet de récupére l'état de l'inscription d'un participant
     *
     * @param {string} etat
     * @returns {string}
     */
    public static getParticipantEtatFormated(etat: string): string{
        let formatedEtat: string = etat;

        const foundedEtat = FormationConstants.participantStatesOptions.find((opt)=>opt.value === etat);
        if(foundedEtat) formatedEtat = foundedEtat.label;

        return formatedEtat;
    }

    /**
     * Retourne le type de document formaté
     *
     * @param {string} type
     * @returns {string}
     */
    static getFormatedDocumentTypes(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, FormationConstants.documentsTypesOptions);
    }

}
