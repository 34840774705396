import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistreInformationsFormData, ISinistreGetApiResponse} from "src/Services/Sinistres.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import DateHelper from "src/Helpers/Date.helper";
import {INotaireGetAllApiResponse, NotairesService} from "src/Services/Notaires.service";
import {INotaire} from "src/Models/Notaire.model";
import NotairesHelper from "src/Helpers/Notaires.helper";
import {SinistresConstants} from "src/Constants/SinistresConstants";


type ISinistreInformationsFormComponentProps = {
    sinistre?: ISinistre,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ISinistreInformationsFormData) => Promise<ISinistreGetApiResponse>,
    onSuccessSubmit: (response: ISinistreGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ISinistreGetApiResponse) => void,
    onCancel: () => void,
}

export default function SinistreInformationsFormComponent(props: ISinistreInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();
    const [notaires, setNotaires] = useState<INotaire[]>(null);
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAllForSelect().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const sinistre: ISinistre = props.sinistre;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "numeroDossier",

                label: "Numéro de dossier",
                placeholder: "Numéro de dossier",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.numeroDossier ? sinistre.numeroDossier : "",
            },
            {
                type: 'selectMultiple',
                fieldName: "notairesDemandeurs",

                label: "Notaire(s) demandeur(s)",
                options: notaires && notaires.length ? NotairesHelper.formatListForOptions(notaires): [],
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValues: sinistre && sinistre.notairesDemandeurs ? NotairesHelper.getIdsFromList(sinistre.notairesDemandeurs) : [],
            },
        ];

        const formElementsColumn2: FormElement[] = [
            {
                type: 'text',
                fieldName: "libelle",

                label: "Libellé du dossier",
                placeholder: "Libellé du dossier",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.libelle ? sinistre.libelle : "",
            },

            {
                type: 'selectMultiple',
                fieldName: "notaireAssignes",

                label: "Notaire(s) mis en cause",
                options: notaires && notaires.length ? NotairesHelper.formatListForOptions(notaires): [],
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",

                oldValues: sinistre && sinistre.notairesAssignes ? NotairesHelper.getIdsFromList(sinistre.notairesAssignes) : [],
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'number',
                fieldName: "anneeOuverture",

                label: "Année d'ouverture",
                placeholder: "Année d'ouverture",
                required: true,
                maxLength:4,
                showFieldErrorDetail: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : "",
            },

            {
                type: 'select',
                fieldName: "importance",

                label: "Importance du dossier",
                options: SinistresConstants.importanceOptions,
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                hideSearchOnMultiple: true,

                oldValue: sinistre && sinistre.importance ? sinistre.importance : "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
            {
                modificators:"-full",
                elements: formElementsColumn2
            },
            {
                modificators:"-full",
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);


    }, [notaires]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISinistreInformationsFormData).then((response: ISinistreGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le sinistre a bien été créé.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive du sinistre.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }

        </div>
    )

}
