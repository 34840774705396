import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICoordonneesFormData} from "src/Services/Commons.service";
import {ICoordonnee} from "src/Models/Coordonnee.model";
import {CoordonneeConstants} from "src/Constants/CoordonneeConstants";
import useCreationSteps from "../../../Hooks/useCreationSteps";


type CoordonneesFormComponentProps = {
    coordonnees?: ICoordonnee[],
    isOnCreationStep?: boolean,

    relationName: string,
    relationId: number,

    submitMethod: (formData: ICoordonneesFormData) => Promise<IApiCustomResponse>,
    onSuccessSubmit: (response: IApiCustomResponse) => void,
    onSuccessSubmitNext?: (response: IApiCustomResponse) => void,
    onCancel: () => void,
}

export default function CoordonneesFormComponent(props: CoordonneesFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const coordonnees: ICoordonnee[] = props.coordonnees;

        let destinationOptionsEmail = CoordonneeConstants.destinationOptionsEmail;
        let destinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephone;

        if (props.relationName == 'office') {
            destinationOptionsEmail = CoordonneeConstants.destinationOptionsEmailOffice;
            destinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephoneOffice;
        }

        if (props.relationName == 'notaire') {
            destinationOptionsEmail = CoordonneeConstants.destinationOptionsEmailNotaire;
            destinationOptionsTelephone = CoordonneeConstants.destinationOptionsTelephoneNotaire;
        }

        const formElementsColumn1: FormElement[] = [
            {
                type: 'hidden',
                fieldName: "relationName",
                modificators: "-hidden",

                oldValue: props.relationName,
            },
            {
                type: 'hidden',
                fieldName: "relationId",
                modificators: "-hidden",

                oldValue: props.relationId.toString(),
            },
            {
                type: 'repeater',
                fieldName: "coordonnees",
                label: "Coordonnées",
                oldRepeatableValues: coordonnees as FormComponentFormData,
                formColumns: [
                    {
                        elements: [
                            {
                                type: 'hidden',
                                fieldName: "id",
                                oldValue: "",
                                modificators: "-hidden"
                            },
                            {
                                type: 'select',
                                fieldName: "type",

                                label: "Type",
                                options: props.relationName == 'notaire' ? CoordonneeConstants.typeOptionsNotaire : CoordonneeConstants.typeOptions,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                            },
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'select',
                                fieldName: "destination",

                                label: "Destination",
                                options: destinationOptionsTelephone,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                                condition:{
                                    value:"telephone",
                                    fieldName:"type"
                                }
                            },
                            {
                                type: 'select',
                                fieldName: "destination",

                                label: "Destination",
                                options: destinationOptionsEmail,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                                condition: {
                                    value:"email",
                                    fieldName:"type"
                                }
                            },
                            {
                                type: 'select',
                                fieldName: "destination",

                                label: "Destination",
                                options: CoordonneeConstants.destinationOptionsFax,
                                modificators: props.isOnCreationStep ? "-on-white" : '',
                                hideSearchOnMultiple: true,
                                required: true,

                                oldValue: "",
                                condition:{
                                    value:"fax",
                                    fieldName:"type"
                                }
                            },
                            {
                                type: 'url',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "url",
                                }
                            }
                        ],
                    },
                    {
                        elements: [
                            {
                                type: 'tel',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "telephone",
                                }
                            },
                            {
                                type: 'email',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "email",
                                }
                            },
                            {
                                type: 'tel',
                                fieldName: "coordonnees",

                                label: "Détail",
                                required: true,
                                modificators: props.isOnCreationStep ? "-on-white" : '',

                                oldValue: "",
                                condition: {
                                    fieldName: "type",
                                    value: "fax",
                                }
                            },
                        ],
                    }
                ],
                required: false,
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);

    }, [props.coordonnees]);


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData as ICoordonneesFormData).then((response: IApiCustomResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations des coordonnées ont bien été mises à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des coordonnées.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                errorFieldsMessages={errorFieldsMessages}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorMessages={errorMessages}/>
        </div>
    )
}
