import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {AutresContactsService, IOrganismesGetApiResponse, IPersonneExterieureGetApiResponse, IPersonneExterieureIdentiteFormData} from "src/Services/AutresContacts.service";
import {useEffect, useState} from "react";
import {Store as notificationSystem} from "react-notifications-component";
import {IPersonneExterieure} from "src/Models/PersonneExterieure.model";
import {AppConstants} from "src/Constants/AppConstants";
import {PersonneExterieureConstants} from "src/Constants/PersonneExterieureConstants";
import {IOption} from "src/Components/Fields/Select.field.component";
import {IGroupe} from "src/Models/Groupe.model";
import {GroupesService, IGroupesGetApiResponse} from "src/Services/Groupes.service";
import GroupesHelper from "src/Helpers/Groupes.helper";
import useCreationSteps from "src/Hooks/useCreationSteps";


type IPersonneExterieureIdentiteFormProps = {
    personne?: IPersonneExterieure,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IPersonneExterieureIdentiteFormData) => Promise<IPersonneExterieureGetApiResponse>,
    onSuccessSubmit: (response: IPersonneExterieureGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IPersonneExterieureGetApiResponse) => void,
    onCancel: () => void,
}

export default function PersonneExterieureIdentiteFormComponent(props: IPersonneExterieureIdentiteFormProps){
    const [organismesLoaded, setOrganismesLoaded] = useState<boolean>(false);
    const [groupesLoaded, setGroupesLoaded] = useState<boolean>(false);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [organismesOptions, setOrganismesOptions] = useState<IOption[]>([]);
    const [groupes, setGroupes] = useState<IGroupe[]>([]);
    const {getFormActions} = useCreationSteps();

    /**
     * Récupération des organismes à l'initialisation du composant
     */
    useEffect(() => {
        const autresContactsService: AutresContactsService = new AutresContactsService();

        autresContactsService.getOrganismes().then((response: IOrganismesGetApiResponse)=> {
            if( response.datas && response.datas.organismes){
                setOrganismesOptions(autresContactsService.formatOrganismesForSelect(response.datas.organismes));
                setOrganismesLoaded(true);
            }
        });
        
        const groupesServices: GroupesService = new GroupesService();
        groupesServices.getAllNonAutomatique().then((response:IGroupesGetApiResponse) => {
            if(response && response.datas && response.datas.groupes){
                setGroupes(response.datas.groupes);
                setGroupesLoaded(true);
            }
        });
    }, []);


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(()=>{
        if(!organismesLoaded || !groupesLoaded) return;

        const personne: IPersonneExterieure = props.personne;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "sexe",

                label: "Sexe",
                placeholder: "Sexe",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.sexeOptions,
                hideSearchOnMultiple: true,
                oldValue: personne && personne.sexe ? personne.sexe : "",
            },
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: personne && personne.nom ? personne.nom : "",
            },
            {
                type: 'text',
                fieldName: "fonction",

                label: "Fonction",
                placeholder: "Fonction",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: personne && personne.fonction ? personne.fonction : "",
            },
            {
                type: 'select',
                fieldName: "organismeId",

                label: "Organisme",
                placeholder: "Organisme",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: organismesOptions,
                oldValue: personne && personne.organisme ? personne.organisme.id.toString() : "",
            },
            {
                type: 'selectMultiple',
                fieldName: "groupes",

                label: "Groupes",
                placeholder: "Groupes",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white" : "",
                //Les groupes automatique ne doivent pas être présent dans la liste déroulante
                //Leur gestion est automatique à l'enregistrement d'une personne
                oldValues: personne && personne.groupes ? GroupesHelper.getIdsFromListWithoutAutomatique(personne.groupes) : [],
                oldValuesReadOnly: personne && personne.groupes ? GroupesHelper.getNamesFromListAutomatique(personne.groupes) : [],

                options: groupes ? GroupesHelper.formatListForOptions(groupes): []
            },
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'select',
                fieldName: "civiliteCourte",

                label: "Civilite (Bloc adresse)",
                placeholder: "Civilite",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.civiliteCourteOptions,
                oldValue: personne && personne.civiliteCourte ? personne.civiliteCourte : "",
            },
            {
                type: 'text',
                fieldName: "prenom",

                label: "Prénom",
                placeholder: "Prénom",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: personne && personne.prenom ? personne.prenom : "",
            },
            {
                type: 'select',
                fieldName: "civilite",

                label: "Formule d'appel",
                placeholder: "Formule d'appel",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: PersonneExterieureConstants.civiliteOptions,
                oldValue: personne && personne.civilite ? personne.civilite : "",
            },
            {
                type: 'radio',
                fieldName: "agrementComptabilite",

                label: "Agrément comptabilité",
                placeholder: "Agrément comptabilité",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',

                options: AppConstants.ouiNonOptions,
                hideSearchOnMultiple: true,
                oldValue: personne && personne.agrementComptabilite ? personne.agrementComptabilite : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'select',
                fieldName: "formulePolitesse",

                label: "Formule de politesse",
                placeholder: "Formule de politesse",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white -skip-line-1-2" : '-skip-line-1-2',

                options: PersonneExterieureConstants.formulesPolitesseOptions,
                oldValue: personne && personne.formulePolitesse ? personne.formulePolitesse : "",
            },
        ]

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[organismesOptions, organismesLoaded, groupesLoaded]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IPersonneExterieureIdentiteFormData).then((response: IPersonneExterieureGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "La personne extérieure a bien été créée." : "La personne extérieure a bien été modifiée." ,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création de la personne extérieure.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                organismesLoaded && groupesLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }
        </div>
    )
}
