import {ISinistre} from "../../Models/Sinistre.model";
import SinistresHelper from "../../Helpers/Sinistres.helper";
import DateHelper from "../../Helpers/Date.helper";
import ReactHtmlParser from "react-html-parser";

type ISinistreDescriptionComponentProps = {
    sinistre: ISinistre,
}

export default function SinistreDescriptionComponent(props: ISinistreDescriptionComponentProps) {
    const sinistre: ISinistre = props.sinistre;

    return (
        <div className="informations-wrapper">
            <div className="columns">
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Objet du sinistre</p>
                        <p className="value">{sinistre.objetFormated ? ReactHtmlParser(sinistre.objetFormated) : '-'}</p>
                    </div>
                    <div className="informations-block">
                        <p className="label">Nature</p>
                        <p className="value">{sinistre.nature ? SinistresHelper.getFormatedNature(sinistre.nature) : '-'}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Date de présentation en CTRC</p>
                        <p className="value">{sinistre.dateExamenCTRC ? DateHelper.getFormatedDate(sinistre.dateExamenCTRC) : '-'}</p>
                    </div>
                </div>
                <div className="column">
                    <div className="informations-block">
                        <p className="label">Rapporteur</p>
                        <p className="value">{sinistre.rapporteur ? SinistresHelper.getFormatedNotaires([sinistre.rapporteur]) : '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
