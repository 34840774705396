import {ISinistre} from "../../Models/Sinistre.model";
import DateHelper from "../../Helpers/Date.helper";
import DocumentLink from "../Documents/DocumentLink.component";
import FileHelper from "../../Helpers/File.helper";
import React, {useEffect, useState} from "react";
import {IDocument} from "../../Models/Document.model";
import SinistresHelper from "../../Helpers/Sinistres.helper";
import {AppConstants} from "../../Constants/AppConstants";
import DocumentsHelper from "src/Helpers/Documents.helper";
import SelectFieldComponent from "../Fields/Select.field.component";
import {SinistresConstants} from "../../Constants/SinistresConstants";

type ISinistreDocumentsComponentProps = {
    sinistre: ISinistre,
}

export default function SinistreDocumentsComponent(props: ISinistreDocumentsComponentProps) {
    const [documentsList, setDocumentsList] = useState<IDocument[]>([]);
    const [documentsFiltered, setDocumentsFiltered] = useState<IDocument[]>([]);
    const [orderDirection, setOrderDirection] = useState<string>(AppConstants.orderType.asc);
    const [orderBy, setOrderBy] = useState<string>('date');
    const [filterBy, setFilterBy] = useState<string>("type");
    const [filterByValue, setFilterByValue] = useState<string>('');

    useEffect(() => {
        if(props.sinistre && props.sinistre.documents){
            const newDocumentsList: IDocument[] = props.sinistre.documents;
            setDocumentsList(newDocumentsList);
        }
    }, [props.sinistre])


    useEffect(() => {
        filterAndOrderDocuments();
    }, [documentsList, orderBy, orderDirection, filterByValue])

    /**
     * Permet le tri et le filtre des documents
     */
    const filterAndOrderDocuments = (): void => {
        const newDocuments = DocumentsHelper.filterAndOrder(documentsList, orderBy, orderDirection, filterBy, filterByValue)
        setDocumentsFiltered(newDocuments);
    }


    return (
        <>
            {
                documentsList && (documentsList.length > 0) &&
                <div className="list-filters -documents-list">
                    <i className={`icon icon-filtre`}/>

                    <SelectFieldComponent fieldName="notaireDocumentsFilterBy"
                                          label="Trier par"
                                          options={SinistresConstants.documentsTypesOptions}
                                          errors={null}
                                          placeholder="&nbsp;"
                                          hideSearch={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={filterByValue ? filterByValue : ""}
                                          onChange={(value: string) => setFilterByValue(value)}
                    />
                    <SelectFieldComponent fieldName="notaireDocumentsOrder"
                                          label="Trier par"
                                          options={SinistresConstants.documentsOrderOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white`}
                                          oldValue={orderBy ? orderBy : ""}
                                          onChange={(value: string) => setOrderBy(value)}
                    />
                    <SelectFieldComponent fieldName="notaireDocumentsOrderDirectement"
                                          label="Ordre du tri"
                                          options={AppConstants.orderDirectionOptions}
                                          errors={null}
                                          placeholder=""
                                          hideSearch={true}
                                          hideEmptyOption={true}
                                          modificators={`-inline -on-white -small-select`}
                                          oldValue={orderDirection ? orderDirection : ""}
                                          onChange={(value: string) => setOrderDirection(value)}
                    />
                </div>
            }

            <div className="informations-wrapper">
            {
                documentsFiltered && (documentsFiltered.length > 0) && documentsFiltered.map((d, index) =>
                    <div className="columns" key={index}>
                        <p className="title">{ `${DateHelper.getMonthFromDate(d.date)} ${DateHelper.getYearFromDate(d.date)}` }</p>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Type</p>
                                <p className="value">{d.type ? SinistresHelper.getFormatedDocumentTypes(d.type) : '-'}</p>
                            </div>
                        </div>


                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Date</p>
                                <p className="value">{d.date ? DateHelper.getFormatedDate(d.date) : '-'}</p>
                            </div>
                        </div>

                        <div className="column">
                            <div className="informations-block">
                                <p className="label">Fichier</p>
                                {
                                    !d.fichier &&
                                    <p className="value">-</p>
                                }
                                {
                                    d.fichier &&
                                    <DocumentLink icon="icon-documents" link={d.fichier ? FileHelper.getFileUrl(d.fichier) : '-'} />
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
        </>
    );
}
