import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useRef, useState} from "react";
import {AppConstants, ICreationSteps} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import {IApiCustomResponse} from "src/Services/Api.service";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import UseSinistre from "src/Hooks/UseSinistre";
import {
    ISinistreDescriptionFormData,
    ISinistreDocumentsFormData,
    ISinistreInformationsFormData,
    SinistresService
} from "src/Services/Sinistres.service";
import {ISinistre} from "src/Models/Sinistre.model";
import AsideSinistresComponent from "src/Components/Aside/AsideSinistres.component";
import AccordionWithFormSwitchComponent from "src/Components/Accordions/AccordionWithFormSwitch.component";
import SinistreInformationsFormComponent from "src/Components/Forms/Sinistres/SinistreInformationsForm.component";
import SinistreInformationsComponent from "src/Components/Sinistres/SinistreInformations.component";
import SinistreDescriptionFormComponent from "src/Components/Forms/Sinistres/SinistreDescriptionForm.component";
import SinistreDescriptionComponent from "src/Components/Sinistres/SinistreDescription.component";
import {SinistresConstants} from "src/Constants/SinistresConstants";
import SinistreDocumentsFormForm from "../../Components/Forms/Sinistres/SinistreDocumentsForm.component";
import SinistreDocumentsComponent from "../../Components/Sinistres/SinistreDocuments.component";


type ISinistreFicheScreenProps = {
    sid: string
}
export default function SinistreFicheScreen(props: ISinistreFicheScreenProps){
    const informationsAccordionRef = useRef(null);
    const descriptionAccordionRef = useRef(null);
    const documentsAccordionRef = useRef(null);

    const {getSinistreById} = UseSinistre();
    const [sinistre, setSinistre] = useState<ISinistre>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const sinistresService: SinistresService = new SinistresService();
    const [creationSteps, setCreationSteps] = useState<ICreationSteps[]>([]);
    const [formOpened, setFormOpened] = useState<boolean>(false);


    useEffect(() => {
        if(!sinistre) return;

        const breadcrumbLinks = [
            {text: "Sinistres", link: AppConstants.pathSinistres},
            {text: sinistre.libelle},
        ];
        setBreadcrumbLinks(breadcrumbLinks);


        setCreationSteps( SinistresConstants.creationSteps);

    }, [sinistre, loaded])



    useEffect(() => {
        if( !props.sid ){
            history.push(AppConstants.pathSinistres);
        }

        getSinistre();

    }, [props.sid])


    /**
     * Récupération du sinistre selon l'uid
     */
    const getSinistre = (): void => {
        getSinistreById(props.sid, (sinistre: ISinistre) => {
            setSinistre(sinistre);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };


    /**
     * Callback au succès du submit du formulaire
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getSinistre();

        closeForms();
    };

    /**
     * Callback au succès du submit de l'envoi de courrier
     *
     * @param {IApiCustomResponse} response
     */
    const onSuccessSubmitCourrierForm = (response: IApiCustomResponse): void => {
        getSinistre();
    };

    /**
     * Callback à l'annulation du formulaire
     */
    const onCancelForm = (): void => {
        closeForms(true);
    };

    /**
     * Permet la fermeture des formulaires
     *
     * @param {boolean} isCancel
     */
    const closeForms = (isCancel?: boolean): void => {
        if((informationsAccordionRef && informationsAccordionRef.current) ){
            informationsAccordionRef.current.hideForm(isCancel);
        }
        if((descriptionAccordionRef && descriptionAccordionRef.current) ){
            descriptionAccordionRef.current.hideForm(isCancel);
        }
        if((documentsAccordionRef && documentsAccordionRef.current) ){
            documentsAccordionRef.current.hideForm(isCancel);
        }
    }

    return (
        <>
            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent breadcrumbLinks={breadcrumbLinks} text={sinistre.libelle} icon="icon-sinistres" modificators="" onSearch={null} />

                    <TabsContainerComponent onClickTabCallback={() => {closeForms(); setFormOpened(false);}}>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionWithFormSwitchComponent
                                    ref={informationsAccordionRef}
                                    title="Informations"
                                    icon="icon-informations"
                                    contentShowedOnInit={true}
                                    contentType="sinistre"
                                    contentId={sinistre.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SinistreInformationsFormComponent sinistre={sinistre} submitMethod={(formData: ISinistreInformationsFormData)=>sinistresService.updateInformations(sinistre.id, formData)} onSuccessSubmit={onSuccessSubmitForm} onCancel={onCancelForm} />}
                                    infos={<SinistreInformationsComponent sinistre={sinistre} />}
                                />

                                <AccordionWithFormSwitchComponent
                                    ref={descriptionAccordionRef}
                                    title="Description"
                                    icon="icon-description"
                                    contentShowedOnInit={true}
                                    contentType="sinistre"
                                    contentId={sinistre.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SinistreDescriptionFormComponent sinistre={sinistre}
                                                                            submitMethod={(formData: ISinistreDescriptionFormData) => sinistresService.updateDescription(sinistre.id, formData)}
                                                                            onSuccessSubmit={onSuccessSubmitForm}
                                                                            onCancel={onCancelForm}/>}
                                    infos={<SinistreDescriptionComponent sinistre={sinistre}/>}
                                />

                                <AsideSinistresComponent type="fiche" sinistre={sinistre} />
                            </div>
                        </TabPanelComponent>





                        <TabPanelComponent label="Documents" icon="icon-documents">
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionWithFormSwitchComponent
                                    ref={descriptionAccordionRef}
                                    title="Documents"
                                    icon="icon-documents"
                                    contentShowedOnInit={true}
                                    contentType="sinistre"
                                    contentId={sinistre.id}
                                    onFormOpen={() => {setFormOpened(true);}}
                                    onFormClose={() => {setFormOpened(false);}}
                                    hideEditButton={formOpened}
                                    form={<SinistreDocumentsFormForm sinistre={sinistre}
                                                                     submitMethod={(formData: ISinistreDocumentsFormData) => sinistresService.updateDocuments(sinistre.id, formData)}
                                                                     onSuccessSubmit={onSuccessSubmitForm}
                                                                     onCancel={onCancelForm}/>}
                                    infos={<SinistreDocumentsComponent sinistre={sinistre}/>}
                                />

                                <AsideSinistresComponent type="fiche" sinistre={sinistre} />
                            </div>
                        </TabPanelComponent>
                    </TabsContainerComponent>
                </LayoutComponent>
            }
        </>
    )
}
