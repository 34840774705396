import React, {useEffect, useState} from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {SinistresConstants} from "src/Constants/SinistresConstants";
import {NotairesService} from "../../Services/Notaires.service";
import {INotaire} from "../../Models/Notaire.model";
import NotairesHelper from "../../Helpers/Notaires.helper";

type SinistresAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,
}
export default function SinistresAdvancedSearchComponent(props: SinistresAdvancedSearchComponentProps){
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesCTRCLoaded, setNotairesCTRCLoaded] = useState<boolean>(false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [notairesCTRC, setNotairesCTRC] = useState<INotaire[]>([]);


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getAll().then((response) => {
            if( response && response.datas && response.datas.notaires){
                setNotaires(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

        notairesService.getCTRC().then((response) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesCTRC(response.datas.notaires);
                setNotairesCTRCLoaded(true);
            }
        });
    }, []);




    return (
        <>
            {
                notairesLoaded && notairesCTRCLoaded &&
                <AdvancedSearchComponent
                    title="Sinistres"
                    showSummary={props.showSummary}
                    modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
                    showAdvancedSearch={props.showAdvancedSearch}
                    filters={props.filters}
                    columns={[
                        {
                            elements: [
                                {
                                    type: 'hidden',
                                    fieldName: "advanced",
                                    oldValue: "1",
                                    modificators: "-hidden"
                                },
                                {
                                    type: "text",
                                    fieldName: "numeroDossier",
                                    label: "Numéro de dossier",
                                    modificators:"-on-white",
                                    oldValue: props.filters.numeroDossier as string || ""
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "notairesDemandeurs",
                                    label: "Notaire(s) demandeur(s)",
                                    modificators:"-on-white",
                                    oldValues: props.filters.notairesDemandeurs as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                                {
                                    type: "select",
                                    fieldName: "nature",
                                    label: "Nature",
                                    modificators:"-on-white",
                                    hideSearchOnMultiple: true,
                                    oldValue: props.filters.nature as string || "",
                                    options: SinistresConstants.natureOptions,
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "text",
                                    fieldName: "libelle",
                                    label: "Libellé",
                                    modificators:"-on-white",
                                    oldValue: props.filters.libelle as string || ""
                                },
                                {
                                    type: "selectMultiple",
                                    fieldName: "notairesAssignes",
                                    label: "Notaire(s) assigné(s)",
                                    modificators:"-on-white",
                                    oldValues: props.filters.notairesAssignes as string[] || [],
                                    options: notaires ? NotairesHelper.formatListForOptions(notaires) : [],
                                },
                            ]
                        },
                        {
                            elements: [
                                {
                                    type: "select",
                                    fieldName: "rapporteur",
                                    label: "Rapporteur",
                                    modificators:"-on-white",
                                    oldValues: props.filters.rapporteur as string[] || [],
                                    options: notairesCTRC ? NotairesHelper.formatListForOptions(notairesCTRC) : [],
                                },
                            ]
                        },
                    ]}
                    onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
                    onClose={() => props.setShowAdvancedSearch()}
                    onSearch={props.onSearch}
                />
            }

        </>
    )

}
