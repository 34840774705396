import React from "react";
import AdvancedSearchComponent from "src/Components/Search/AdvancedSearch.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import {NotairesConstants} from "src/Constants/NotairesConstants";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import {AdresseConstants} from "../../../Constants/AdresseConstants";

type NotairesAdvancedSearchComponentProps = {
    showSummary: boolean,
    showAdvancedSearch: boolean,
    filters: IFilterData,
    updateFilters: (filter: IFilterData) => void
    setShowAdvancedSearch: () => void,
    onSearch: (formData: FormComponentFormData) => void,

    isActifs?: boolean,
    isAnciens?: boolean,

}
export default function NotairesAdvancedSearchComponent(props: NotairesAdvancedSearchComponentProps){
    return (
        <AdvancedSearchComponent
            title="Notaires"
            showSummary={props.showSummary}
            modificators={`${!props.showAdvancedSearch ? '-hide' : ''}`}
            showAdvancedSearch={props.showAdvancedSearch}
            filters={props.filters}
            columns={[
             {
                 elements: [
                     {
                         type: 'hidden',
                         fieldName: "advanced",
                         oldValue: "1",
                         modificators: "-hidden"
                     },
                     {
                         type: "text",
                         fieldName: "nom",
                         label: "Nom",
                         modificators:"-on-white",
                         oldValue: props.filters.nom as string || ""
                     },
                     {
                         type: "text",
                         fieldName: "prenom",
                         label: "Prénom",
                         modificators:"-on-white",
                         oldValue: props.filters.prenom as string || ""
                     },{
                         type: "selectMultiple",
                         fieldName: "fonctions",
                         label: "Fonctions",
                         modificators:"-on-white",
                         optionsGroup: NotairesConstants.fonctionsOptions,
                         oldValues: props.filters.fonctions as string[] || []
                     },
                     {
                         type: "text",
                         fieldName: "nom_office",
                         label: "Nom office",
                         modificators:"-on-white",
                         oldValue: props.filters.nom_office as string || ""
                     },
                     {
                         type: "text",
                         fieldName: "CRPCEN_office",
                         label: "CRPCEN office",
                         modificators:"-on-white",
                         oldValue: props.filters.CRPCEN_office as string || ""
                     },
                 ]
             },
             {
                 elements: [
                     {
                         type: "text",
                         fieldName: "ville_office",
                         label: "Ville office",
                         modificators:"-on-white",
                         oldValue: props.filters.ville_office as string || ""
                     },
                     {
                         type: "select",
                         fieldName: "arrondissement",
                         label: "Arrondissement",
                         modificators: "-on-white",
                         options: AdresseConstants.arrondissementsOptions, 
                         oldValue: props.filters.arrondissement as string || ""
                     },
                     {
                         type: "select",
                         fieldName: "tribunal_judiciaire",
                         label: "Tribunal judiciaire",
                         modificators: "-on-white",
                         options: AdresseConstants.tribunalJudiciaireOptions,
                         hideSearchOnMultiple: true,
                         oldValue: props.filters.tribunal_judiciaire as string || ''
                     },
                     {
                         type: "selectMultiple",
                         fieldName: "type",
                         hideSearchOnMultiple: true,
                         label: "Type",
                         modificators:"-on-white",
                         options: props.isAnciens ? NotairesConstants.typesAnciensFullOptions : NotairesConstants.typesActifsOptions,
                         oldValues: props.filters.type as string[] || []
                     },
                     {
                         type: "text",
                         fieldName: "num_CSN",
                         label: "Num. CSN",
                         modificators:"-on-white",
                         oldValue: props.filters.num_CSN as string || ""
                     }
                 ]
             },
             {
                 elements: [
                     {
                         type: "text",
                         fieldName: "num_siret",
                         label: "Num. Siret",
                         modificators:"-on-white",
                         oldValue: props.filters.num_siret as string || ""
                     },
                     {
                         type: "selectMultiple",
                         hideSearchOnMultiple: true,
                         fieldName: "langues_parlees",
                         label: "Langues parlées",
                         modificators:"-on-white",
                         options: NotairesConstants.languesParleesOptions,
                         oldValues: props.filters.langues_parlees as string[] || []
                     },
                     {
                         type: "selectMultiple",
                         hideSearchOnMultiple: true,
                         fieldName: "departement",
                         label: "Département",
                         modificators:"-on-white",
                         options: OfficesConstants.departementsOptions,
                         oldValues: props.filters.departement as string[] || []
                     },
                     {
                         type: "text",
                         fieldName: "predecesseurs",
                         label: "Prédécesseurs",
                         modificators:"-on-white",
                         oldValue: props.filters.predecesseurs as string || ""
                     },
                 ]
             },
            ]}
            onRemoveFilter={(filter: IFilterData) => props.updateFilters(filter)}
            onClose={() => props.setShowAdvancedSearch()}
            onSearch={props.onSearch}
        />
    )

}
