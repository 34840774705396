import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {ICorrespondanceGetApiResponse, ICorrespondanceFormData} from "src/Services/Correspondances.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ICorrespondance} from "src/Models/Correspondance.model";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {INotaire} from "src/Models/Notaire.model";
import {INotaireGetAllApiResponse, NotairesService} from "src/Services/Notaires.service";
import NotairesHelper from "src/Helpers/Notaires.helper";
import bgSignature from "src/assets/images/placeholders/signature-upload.png";
import {NotairesConstants} from "../../../../Constants/NotairesConstants";


type CorrespondanceSignatureInformationsFormProps = {
    correspondance?: ICorrespondance,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ICorrespondanceFormData) => Promise<ICorrespondanceGetApiResponse>,
    onSuccessSubmit: (response: ICorrespondanceGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ICorrespondanceGetApiResponse) => void,
    onCancel: () => void,
}

export default function CorrespondanceSignatureInformationsForm(props: CorrespondanceSignatureInformationsFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(null);
    const [notairesList, setNotairesList] = useState<INotaire[]>([]);
    const [selectedNotaire, setSelectedNotaire] = useState<INotaire>(null);


    useEffect( () => {
        const notairesService: NotairesService = new NotairesService();
        notairesService.getAllForSelect().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesList(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });
    }, []);


    useEffect(()=>{
        if(!notairesLoaded){
            return;
        }
        const {correspondance} = props;

        //Gestion de la colonne 1
        const formElementsColumn1: FormElement[] = [
            {
                type: "select",
                fieldName: "signatureNotaireId",
                label: "Notaire expéditeur",
                modificators: props.isOnCreationStep ? "-on-white" : '',

                oldValue: correspondance.signatureNotaireId ? correspondance.signatureNotaireId.toString() : "",
                options: notairesList ? NotairesHelper.formatListForOptions(notairesList, null, NotairesConstants.typesAnciensLight) : [],
            },
            {
                type: "mention",
                label: "Si un notaire est choisi, ses données sont reprises à l'enregistrement du formulaire. Les données peuvent ensuite être modifiées via ce formulaire."
            }
        ];

        //Gestion de la colonne 2
        const formElementsColumn2: FormElement[] = [];
        formElementsColumn2.push({
            type: 'text',
            fieldName: "signatureNom",

            label: "Nom",
            required: false,
            disabled: correspondance.signatureNotaireId ? true: false,
            modificators: props.isOnCreationStep ? "-on-white" : '',

            oldValue: selectedNotaire ? selectedNotaire.nom : (correspondance.signatureNom || ""),
        });
        formElementsColumn2.push({
            type: 'text',
            fieldName: "signaturePrenom",

            label: "Prénom",
            required: false,
            disabled: correspondance.signatureNotaireId ? true: false,
            modificators: props.isOnCreationStep ? "-on-white" : '',

            oldValue: selectedNotaire ? selectedNotaire.prenom : (correspondance.signaturePrenom || ""),
        });
        formElementsColumn2.push({
            type: 'text',
            fieldName: "signatureFonction",

            label: "Fonction",
            required: false,
            modificators: props.isOnCreationStep ? "-on-white" : '',

            oldValue: selectedNotaire ? NotairesHelper.getFormatedFonctions(selectedNotaire.fonctions) : (correspondance.signatureFonction || ""),
        });
        formElementsColumn2.push({
            type: 'file',
            fieldName: "signatureFichier",

            label: "Signature",
            placeholder: "Signature",
            background: bgSignature,
            showImage: true,
            showImageDeleteButton: true,
            required: false,
            disabled: correspondance.signatureNotaireId ? true: false,

            oldValue: selectedNotaire ? selectedNotaire.signature : (correspondance.signatureFichier || ""),
        });


        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit, true );
        setFormActions(currentFormActions);
    },[notairesLoaded, notairesList, selectedNotaire]);


    /**
     * On écoute le changement de valeur du select des notaires
     *
     * @param {FormComponentFormData} formData
     */
    const onFormDataChange = (formData: FormComponentFormData): void => {
        if(formData['signatureNotaireId']){
            const currentNotaireId = formData['signatureNotaireId'];
            const currentNotaire = notairesList.find( (n) => n.id == currentNotaireId);

            if(currentNotaire){
                setSelectedNotaire(currentNotaire);
            }
        }
    }


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData).then((response: ICorrespondanceGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La signature a bien été mise à jour.",
                type: "success"
            });

        }).catch((error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de la signature.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}
                onFormDataChange={(formData =>( onFormDataChange(formData)))}
            />
        </div>
    )
}
