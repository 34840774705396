import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";
import {IFormationGetApiResponse, IFormationFormData} from "src/Services/Formations.service";
import {FormationConstants} from "src/Constants/FormationsConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {IFormation} from "src/Models/Formation.model";
import useCreationSteps from "src/Hooks/useCreationSteps";


type FormationInformationsFormProps = {
    formation?: IFormation,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IFormationFormData) => Promise<IFormationGetApiResponse>,
    onSuccessSubmit: (response: IFormationGetApiResponse) => void,
    onSuccessSubmitNext?: (response: IFormationGetApiResponse) => void,
    onCancel: () => void,
}

export default function FormationInformationsForm(props: FormationInformationsFormProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        const {formation} = props;
        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "categorie",
                label: "Catégorie",
                placeholder: "Catégorie",
                hideSearchOnMultiple: true,
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.categorie ? formation.categorie : "",
                optionsGroup: FormationConstants.categoriesOptions,
            },
        ];

        //Si la formation est déjà créée et que l'état n'est pas "passée", ajout la selection de l'état
        //(à la création, l'état est par défaut à actif)
        //(le changement d'état en "passée" est automatique, tâche CRON)
        if (formation && formation.etat && formation.etat !== FormationConstants.statePassee) {
            formElementsColumn1.push({
                type: 'select',
                fieldName: "etat",
                label: "État",
                placeholder: "État",
                hideSearchOnMultiple: true,
                required: true,
                hideEmptyOption: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: formation && formation.etat ? formation.etat : "",
                optionsGroup: FormationConstants.statesOptions,
            });
        }

        formElementsColumn1.push({
            type: 'text',
            fieldName: "titre",

            label: "Titre",
            placeholder: "Titre",
            required: true,
            modificators: props.isOnCreationStep ? "-on-white" : '',
            oldValue: formation && formation.titre ? formation.titre : "",
        });

        const formElementsColumn2: FormElement[] = [

            {
                type: 'datetime-local-with-step',
                withTime: true,
                timeInterval: 15,
                fieldName: "date",
                label: "Date",
                placeholder: "Date",
                required: true,
                oldValue: formation && formation.date ? formation.date : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
            {
                type: 'datetime-local-with-step',
                withTime: true,
                timeInterval: 15,
                fieldName: "dateFin",
                label: "Date de fin",
                placeholder: "Date",
                required: true,
                oldValue: formation && formation.dateFin ? formation.dateFin : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'date',
                fieldName: "dateLimiteReponse",
                label: "Date limite de réponse",
                placeholder: "Date",
                required: false,
                oldValue: formation && formation.dateLimiteReponse ? formation.dateLimiteReponse : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
            {
                type: 'date',
                fieldName: "dateConfirmation",
                label: "Date de confirmation",
                placeholder: "Date",
                required: false,
                oldValue: formation && formation.dateConfirmation ? formation.dateConfirmation : "",
                modificators: props.isOnCreationStep ? "-on-white" : '',
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
            {
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit);
        setFormActions(currentFormActions);
    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if (!props.submitMethod) return;

        props.submitMethod(formData).then((response: IFormationGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (goNextStep && props.onSuccessSubmitNext) {
                props.onSuccessSubmitNext(response);
            } else if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }


            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: props.isOnCreationStep ? "La formation a bien été créée." : "La formation a bien été mise à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}/>
        </div>
    )
}
