import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistreDescriptionFormData, ISinistreGetApiResponse} from "src/Services/Sinistres.service";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {SinistresConstants} from "src/Constants/SinistresConstants";
import {INotaire} from "src/Models/Notaire.model";
import {INotaireGetAllApiResponse, NotairesService} from "src/Services/Notaires.service";
import NotairesHelper from "src/Helpers/Notaires.helper";


type ISinistreDescriptionFormComponentProps = {
    sinistre?: ISinistre,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ISinistreDescriptionFormData) => Promise<ISinistreGetApiResponse>,
    onSuccessSubmit: (response: ISinistreGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ISinistreGetApiResponse) => void,
    onCancel: () => void,
}

export default function SinistreDescriptionFormComponent(props: ISinistreDescriptionFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [notairesLoaded, setNotairesLoaded] = useState<boolean>(false);
    const [notairesCTRC, setNotairesCTRC] = useState<INotaire[]>([]);
    const {getFormActions} = useCreationSteps();


    useEffect(() => {
        const notairesService: NotairesService = new NotairesService();

        notairesService.getCTRC().then((response: INotaireGetAllApiResponse) => {
            if( response && response.datas && response.datas.notaires){
                setNotairesCTRC(response.datas.notaires);
                setNotairesLoaded(true);
            }
        });

    }, []);

    /**
     * Initialisation du formulaire à partir des infos de l'utilsiateur
     */
    useEffect(() => {
        if(!notairesCTRC) return;

        const sinistre: ISinistre = props.sinistre;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'textarea',
                fieldName: "objet",

                label: "Objet du sinistre",
                placeholder: "Objet du sinistre",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.objet ? sinistre.objet : "",
            }
        ];
        const formElementsColumn2: FormElement[] = [
            {
                type: 'select',
                fieldName: "nature",

                label: "Nature",
                placeholder: "Nature",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                oldValue: sinistre && sinistre.nature ? sinistre.nature : "",

                options: SinistresConstants.natureOptions,
                hideSearchOnMultiple: true,
            },
            {
                type: 'date',
                fieldName: "dateExamenCTRC",

                label: "Date de présentation en CTRC",
                placeholder: "Présentation en CTRC",
                required: true,
                modificators: props.isOnCreationStep ? "-on-white" : '',
                oldValue: sinistre && sinistre.dateExamenCTRC ? sinistre.dateExamenCTRC : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: 'select',
                fieldName: "rapporteurId",

                label: "Rapporteur",
                placeholder: "Rapporteur",
                required: false,
                modificators: props.isOnCreationStep ? "-on-white -form-field-open-options-at-top" : '-form-field-open-options-at-top',
                oldValue: sinistre && sinistre.rapporteur ? sinistre.rapporteur.id.toString() : "",
                options: notairesCTRC ? NotairesHelper.formatListForOptions(notairesCTRC) : []
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators:"",
                elements: formElementsColumn1
            },
            {
                modificators:"",
                elements: formElementsColumn2
            },
            {
                modificators:"",
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);


    }, [notairesCTRC]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISinistreDescriptionFormData).then((response: ISinistreGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Les informations descriptive du sinistre ont bien été modifié.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations descriptive du sinistre.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            {
                notairesLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }

        </div>
    )

}
