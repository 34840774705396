import React, {useEffect, useState} from "react";
import LabelComponent from "./Label.component";
import {IFormField} from "./Input.field.component";
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/noneditable';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import {IApiErrorMessage} from "src/Services/Api.service";
import StringHelper from "../../Helpers/String.helper";


type IWysiwygFieldComponentProps = IFormField & {
    oldValue?: string,
    onChange: (value: string) => void,
}

export default function WysiwygFieldComponent(props: IWysiwygFieldComponentProps){
    const [currentValue, setCurrentValue] = useState<string>(null);
    const [contentEditor, setContentEditor] = useState<any>();
    const [errors, setErrors] = useState<IApiErrorMessage>(null);

    useEffect(()=> {
        setTimeout(() => {
            setCurrentValue(props.oldValue || "");
        }, 100)
    }, [props.oldValue]);


    useEffect(()=> {
        setErrors(props.errors)
    }, [props.errors]);


    /**
     * Ecoute du changement dans l'éditeur
     *
     * @param {string} content
     * @param editor
     */
    const handleEditorChange = (content: string, editor: any) => {
        content = StringHelper.removeAccents(content)
        setContentEditor(content);

        //setCurrentValue(content);
        props.onChange(content);

        //On reset l'état d'erreur
        if(errors) {
            setErrors(null);
        }
    }

    return (
        <div className={`form-field ${props.modificators || ""}`}>
            {props.label && <LabelComponent fieldName={props.fieldName} label={props.label} modificators={`${props.labelModificators || ''}`} isRequired={props.required} />}

            <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                <Editor
                    initialValue={currentValue}
                    init={{
                        skin: false,
                        content_css: false,
                        height: 400,
                        menubar: false,
                        relative_urls: false,
                        remove_script_host: false,
                        convert_urls: false,
                        plugins: [
                            'link','table','paste','code','noneditable','lists'
                        ],
                        noneditable_editable_class: 'mceEditable',
                        fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px",
                        branding: false,
                        //contextmenu: "copy, paste, link, fontsizes",
                        contextmenu: false,
                        paste_as_text: true,
                        toolbar:
                            'fontsizeselect | \
                            bold underline italic | \
                            table | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | \
                            link | code'
                    }}
                    value={contentEditor}
                    onEditorChange={handleEditorChange}
                />
            </div>
            {props.showMentions &&
            <div className="form-field-mention">
                Attention, à partir de 15 sauts de lignes (ou 27 retour à la ligne) une 2e page sera automatiquement créée.
            </div>
            }
        </div>
    )
}
