import {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {defaultNotificationConfig} from "src/Shared/config";
import {Store as notificationSystem} from "react-notifications-component";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {IParametre} from "src/Models/Parametre.model";
import {IParametreGetApiResponse, IParametreInformationsFormData} from "src/Services/Parametres.service";
import {ParametresConstants} from "src/Constants/ParametresConstants";
import {AppConstants} from "../../../Constants/AppConstants";
import DateHelper from "../../../Helpers/Date.helper";


type IParametreInformationsFormComponentProps = {
    parametre?: IParametre,
    isOnCreationStep?: boolean,

    submitMethod: (formData: IParametreInformationsFormData) => Promise<IParametreGetApiResponse>,
    onSuccessSubmit: (response: IParametreGetApiResponse) => void,
    onCancel: () => void,
}

export default function ParametreInformationsFormComponent(props: IParametreInformationsFormComponentProps) {
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();


    /**
     * Initialisation du formulaire
     */
    useEffect(() => {
        const parametre: IParametre = props.parametre;

        let valueForField = parametre.valeur;
        if(parametre.type == "date"){
            valueForField = DateHelper.getFormatedDateForInput(parametre.dateFormated);
        }

        let formElementsColumn1: FormElement[] = [];
        let formElementsColumn2: FormElement[] = [];
        let formElementsColumn3: FormElement[] = [];

        if(props.isOnCreationStep ){
            formElementsColumn1 = [
                {
                    type: 'text',
                    fieldName: "nom",

                    label: "Nom",
                    modificators: " -on-white",
                    required: true,

                    oldValue: parametre && parametre.nom ? parametre.nom : "",
                },
                {
                    type: 'text',
                    fieldName: "valeur",

                    label: "Valeur",
                    modificators: " -on-white",
                    required: true,

                    oldValue: parametre && parametre.valeur ? valueForField : "",
                },
            ];
            formElementsColumn2 = [
                {
                    type: 'text',
                    fieldName: "slug",

                    label: "Slug",
                    modificators: " -on-white",
                    required: true,

                    oldValue: parametre && parametre.slug ? parametre.slug : "",
                },
            ];
            formElementsColumn3 = [
                {
                    type: 'select',
                    fieldName: "module",

                    label: "Module",
                    modificators: " -on-white",
                    required: true,

                    options: ParametresConstants.modulesOptions,
                    hideSearchOnMultiple: true,

                    oldValue: parametre && parametre.module ? parametre.module : "",
                },
            ];
        }
        else{
            formElementsColumn1 = [
                {
                    type: 'hidden',
                    fieldName: "slug",
                    oldValue: parametre && parametre.slug ? parametre.slug : "",
                    modificators: "-hidden"
                },
                {
                    type: 'hidden',
                    fieldName: "module",
                    oldValue: parametre && parametre.module ? parametre.module : "",
                    modificators: "-hidden"
                },
                {
                    type: 'text',
                    fieldName: "nom",

                    label: "Nom",
                    modificators: '',
                    required: true,

                    oldValue: parametre && parametre.nom ? parametre.nom : "",
                }
            ];


            let formElementValeur: FormElement = null;
            switch(parametre.type){
                case 'number':
                case 'text':
                    formElementValeur = {
                        type: parametre.type,
                        fieldName: "valeur",

                        label: "Valeur",
                        modificators: "",
                        required: true,

                        oldValue: parametre && parametre.valeur ? valueForField : "",
                    };
                    break;

                case 'date':
                    formElementValeur = {
                        type: parametre.type,
                        fieldName: "valeur",

                        label: "Valeur",
                        modificators: "",
                        required: true,

                        oldValue: parametre && parametre.valeur ? valueForField : "",
                    };
                    break;

                case 'ouiNon':
                    formElementValeur = {
                        type: "select",
                        fieldName: "valeur",

                        label: "Valeur",
                        modificators: "",
                        required: true,
                        options: AppConstants.ouiNonOptions,

                        oldValue: parametre && parametre.valeur ? parametre.valeur : "",
                    };
                    break;
            }

            if(formElementValeur) {
                formElementsColumn2.push(formElementValeur);
            }
        }




        const currentFormColumns: FormColumn[] = [
            {
                modificators:"",
                elements: formElementsColumn1
            },
            {
                modificators:"",
                elements: formElementsColumn2
            },
            {
                modificators:"",
                elements: formElementsColumn3
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(props.isOnCreationStep, onCancel, onSubmit );
        setFormActions(currentFormActions);

    }, []);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as IParametreInformationsFormData).then((response: IParametreGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            setErrorFieldsMessages(null);

            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            let successTextMessage = "Le paramètre a bien été ";
            if(props.isOnCreationStep) {
                successTextMessage += "créé.";
            } else {
                successTextMessage += "modifié.";
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: successTextMessage,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                setErrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des informations du paramètre.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={props.isOnCreationStep? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )

}
