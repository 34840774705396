import React, {useEffect, useState} from "react";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {useHistory, useLocation} from "react-router";
import {IApiPaginationLink} from "src/Services/Api.service";
import {ISinistre} from "src/Models/Sinistre.model";
import {ISinistresGetApiResponse, ISinistresGetFormData, SinistresService} from "src/Services/Sinistres.service";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import AsideSinistresComponent from "src/Components/Aside/AsideSinistres.component";
import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import DateHelper from "src/Helpers/Date.helper";
import SinistresHelper from "src/Helpers/Sinistres.helper";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import SinistresAdvancedSearchComponent from "src/Components/Sinistres/SinistresAdvancedSearch.component";
import SinistresExportComponent from "src/Components/Sinistres/SinistresExport.component";
import LoadingComponent from "../../Components/Loading/Loading.component";



type ISinistresListScreenProps = {
};

export default function SinistresListScreen(props: ISinistresListScreenProps){
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [sinistresList, setSinistresList] = useState<ISinistre[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);

    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder,defaultFilters] = useListFilters(AppConstants.pathSinistres);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();


    //Génération des breadcrumb links
    useEffect(() => {
        const breadcrumbLinks = [
            { text: "Sinistres" },
        ];
        setBreadcrumbLinks(breadcrumbLinks);
    }, [])


    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect( () => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params),[]);
        updateFilters(defaultFilters, false, true);
    }, []);

    useEffect(()=>{
        if(!filters) return null;
        launchFilteredSearch();
    },[filters]);

    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters,...formData},true);
        setShowAdvancedSearch(false);
    };



    /**
     * Récupération des sinistres
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        const sinistresService: SinistresService = new SinistresService();

        sinistresService.getSinistres(filters as ISinistresGetFormData).then((response: ISinistresGetApiResponse) => {
            if (response && response.datas.pagination) {
                const newSinistresList = response.datas.pagination.data;
                setSinistresList(newSinistresList);

                //Pagination
                setPaginationLinks(response.datas.pagination.links);

                if (!loaded) {
                    setLoaded(true);
                }
            }
        });
    }





    return (
        <LayoutComponent showMenu={true}>
            <div className={`list ${loaded ? 'border-footer' : ''}`}>
                <PageHeaderComponent
                    breadcrumbLinks={breadcrumbLinks}
                    text="Sinistres"
                    icon="icon-sinistres"
                    onSearch={(keyword => updateFilters({...defaultFilters,keyword},true))}
                    onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}
                />
                {
                    !loaded &&
                    <LoadingComponent />
                }
                {
                    loaded &&
                    <>
                        <TabsContainerComponent>
                            <TabPanelComponent label="Liste" icon="icon-menu">
                                <div className="list-content -right-gutter">

                                    <SinistresAdvancedSearchComponent
                                        showSummary={true}
                                        showAdvancedSearch={showAdvancedSearch}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                        setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                        onSearch={ onAdvancedSearch}
                                    />

                                    <table className={`list-table`}>
                                        <thead className={"headers"}>
                                            <tr>
                                                {[
                                                    {slug: "numeroDossier", label: "Numéro de dossier"},
                                                    {slug: "dateOuverture", label: "Année d’ouverture"},
                                                    {slug: "libelle", label: "Libellé du dossier"},
                                                    {slug: "notairesAssignesOrdered", label: "Notaire(s) mis en cause"},
                                                    {slug: "nature", label: "Nature du sinistre"},
                                                    {slug: "ctrc", label: "Date de présentation en CTRC"},
                                                ].map((item, iIndex) => <th className="th" key={iIndex}>
                                                    <TableHeaderButtonComponent
                                                        direction={filters.orderType as string || AppConstants.orderType.asc}
                                                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                        label={item.label} click={(value: string) => setOrder(value)}/>
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sinistresList && sinistresList.map((sinistre: ISinistre, index: number) =>
                                                    <tr key={index} onClick={() => history.push(`${AppConstants.pathSinistres}/${sinistre.id}`)} className="tr">
                                                        <td className="td -bold">
                                                            {sinistre.numeroDossier}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.dateOuverture ? DateHelper.getFormatedYear(sinistre.dateOuverture) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.libelle}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.notairesAssignes ? SinistresHelper.getFormatedNotaires(sinistre.notairesAssignes) : ''}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.nature ? SinistresHelper.getFormatedNature(sinistre.nature) : ""}
                                                        </td>
                                                        <td className="td">
                                                            {sinistre.dateExamenCTRC ? DateHelper.getFormatedDate(sinistre.dateExamenCTRC) : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>


                                    <PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>

                                    <AsideSinistresComponent type="list" exportCallback={() => setShowExport(true)} filters={filters} />
                                </div>
                            </TabPanelComponent>
                        </TabsContainerComponent>
                    </>
                }
            </div>

            <SinistresExportComponent setShowExport={() => setShowExport(false)} showExport={showExport} filters={filters} />
        </LayoutComponent>
    )
}
