import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {ISignature} from "src/Models/Signature.model";
import useCreationSteps from "src/Hooks/useCreationSteps";
import {ISignatureGetApiResponse, ISignatureInformationsFormData} from "src/Services/Signatures.service";



type ISignaturesFormProps = {
    signature?: ISignature,
    isOnCreationStep?: boolean,

    submitMethod: (formData: ISignatureInformationsFormData) => Promise<ISignatureGetApiResponse>,
    onSuccessSubmit: (response: ISignatureGetApiResponse) => void,
    onSuccessSubmitNext?: (response: ISignatureGetApiResponse) => void,
    onCancel: () => void,
}

export default function SignaturesForm(props: ISignaturesFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const {getFormActions} = useCreationSteps();



    useEffect(()=>{
        const signature: ISignature = props.signature;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "nom",

                label: "Nom",
                placeholder: "Nom",
                required: true,
                modificators: !signature ? "-on-white" : '-on-white',
                oldValue: signature && signature.nom ? signature.nom : "",
            },
            {
                type: 'wysiwyg',
                fieldName: "text",

                label: "Texte",
                placeholder: "Texte",
                required: true,
                modificators: !signature ? "-on-white -wysiwyg" : '-on-white -wysiwyg',
                oldValue: signature && signature.text ? signature.text : "",
            },
        ];


        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            }
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = getFormActions(!signature, onCancel, onSubmit, true );
        setFormActions(currentFormActions);


    },[]);


    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData, goNextStep?: boolean): void => {
        if(!props.submitMethod) return;

        props.submitMethod(formData as ISignatureInformationsFormData).then((response: ISignatureGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if(goNextStep && props.onSuccessSubmitNext){
                props.onSuccessSubmitNext(response);
            }
            else if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }


            const message: string = props.signature ? "La signature a bien été modifiée." : "La signature a bien été créée."
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: message,
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la création de la signature.",
                    type: "danger"
                });
            }
        });
    };

    /**
     * Prend en charge le soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onCancel = (formData: FormComponentFormData): void => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <div className="form-wrapper -edit">
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions={!props.signature ? '-outside-right' : ''}
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages} />
        </div>
    )
}
